$separator-color-light: #313131;
$separator-color: #424242;
$background-color: #1b191b;
$foreground-color: #232223;
$input-background: #232223;

$dark-btn-background: #8d8d8d;
$light-btn-background: #e4e4e4;
$button-text-color: #d0d0d0;

$theme-color-1: #86367e;
$theme-color-2: #3c4b9a;
$theme-color-3: #af67a4;
$theme-color-4: #743c6e;
$theme-color-5: #4b5480;
$theme-color-6: #795d75;

$primary-color: #969696;
$secondary-color: #757575;
$muted-color: #5a5a5a;

$gradient-color-1 : #582553;
$gradient-color-2 : #461d42;
$gradient-color-3 : #64295e;

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 2 6 10 14 19;
$shadowOpacitiesTop: 0.1 0.3 0.6 0.7 0.8;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.1 0.3 0.6 0.7 0.8;

$logoPath: "/assets/img/logo-white.svg";
$logoPathMobile: "/assets/img/logo-mobile.svg";

@import "../_mixins.scss";
@import "../_gogo.style.scss";


